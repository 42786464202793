import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Override, { OverrideTypes } from '../Modals/Override';
import PhoneNumberOverride from '../Modals/PhoneNumberOverride';
import { FaFilter } from 'react-icons/fa';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import {
    DataAnonymizationPropMap,
    DataAnonymizationTitlePropMap
} from '../../lib/dictionaries/DataAnonymizationDictionary';
import { NoDataIndication } from './NoDataIndication';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import { NEVER_ANONYMIZE_MESSAGE } from '../../lib/constants';

type Props = {
    isIncidentAnonymization?: boolean;
    isPhoneAnonymization?: boolean;
};

const styles = {
    addOverrideButton: {
        backgroundColor: '#EBECFF',
        width: '100px',
        height: '30px',
        border: 'none',
        color: '#0036C8',
        fontSize: '9pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'right' as const,
        marginRight: '10px'
    }
};

const lineByLineFormatter = (cell: any, row: any): JSX.Element => {
    return (
        <div>
            {cell.split(',').map((item: any, index: any) => (
                <div key={index}>{item.trim()}</div>
            ))}
        </div>
    );
};

const DataAnonymizationTable = ({ isIncidentAnonymization, isPhoneAnonymization }: Props): JSX.Element => {
    const [selectedDays, setSelectedDays] = useState<number | null>(null);
    const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
    const [openEditOverride, setOpenEditOverride] = useToggle(false);
    const [openAddOverride, setOpenAddOverride] = useToggle(false);
    const [tableFilters, setTableFilters] = useToggle(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const { anonymizationSettings, phoneAnonymizationSettings, isLoadingAnonymizationSettings } = useSelector(
        (state: RootState) => state.dataAnonymization
    );
    countries.registerLocale(localeEn);

    const dataCountryColumn = {
        dataField: DataAnonymizationPropMap.country,
        text: DataAnonymizationTitlePropMap.country,
        sort: true,
        sortFunc(a: string, b: string, order: string) {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({
                  placeholder: DataAnonymizationTitlePropMap.country + '...'
              })
            : false,
        style: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        events: {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick: (e: any, column: any, columnIndex: any, row: any) => {
                setSelectedCountry(row.country);
                setSelectedDays(row.time == NEVER_ANONYMIZE_MESSAGE ? null : parseInt(row.time));
                setOpenEditOverride(true);
            }
        }
    };

    const phoneCountryColumn = {
        dataField: DataAnonymizationPropMap.country,
        text: DataAnonymizationTitlePropMap.country,
        sort: true,
        sortFunc(a: string, b: string, order: string) {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({
                  placeholder: DataAnonymizationTitlePropMap.country + '...'
              })
            : false,
        style: {
            cursor: 'pointer',
            textDecoration: 'underline'
        },
        events: {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick: (e: any, column: any, columnIndex: any, row: any) => {
                setSelectedCountry(row.country);
                setSelectedEventTypes(row.eventTypes.split(', '));
                setOpenEditOverride(true);
            }
        }
    };

    const timeColumn = {
        dataField: DataAnonymizationPropMap.time,
        text: DataAnonymizationTitlePropMap.time,
        sort: true,
        sortFunc: (a: string, b: string, order: string) => {
            if (a == NEVER_ANONYMIZE_MESSAGE) {
                a = '-1';
            }
            if (b == NEVER_ANONYMIZE_MESSAGE) {
                b = '-1';
            }
            return order === 'asc' ? parseInt(b) - parseInt(a) : parseInt(a) - parseInt(b);
        },
        filter: tableFilters
            ? textFilter({
                  placeholder: DataAnonymizationTitlePropMap.time + '...'
              })
            : false
    };

    const eventTypecolumn = {
        dataField: DataAnonymizationPropMap.eventTypes,
        text: DataAnonymizationTitlePropMap.eventTypes,
        sort: true,
        sortFunc: (a: string, b: string, order: string) => {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({
                  placeholder: DataAnonymizationTitlePropMap.eventTypes + '...'
              })
            : false,
        formatter: lineByLineFormatter
    };

    const dateLastModifiedColumn = {
        dataField: DataAnonymizationPropMap.dateLastModified,
        text: DataAnonymizationTitlePropMap.dateLastModified,
        headerClasses: tableFilters ? 'non-filterable-column' : undefined
    };

    const userColumn = {
        dataField: DataAnonymizationPropMap.user,
        text: DataAnonymizationTitlePropMap.user,
        headerClasses: tableFilters ? 'non-filterable-column' : undefined
    };

    const anonymizationSettingsColumns = [dataCountryColumn, timeColumn, dateLastModifiedColumn, userColumn];
    const PhoneAnonymizationSettingsColumns = [
        phoneCountryColumn,
        eventTypecolumn,
        dateLastModifiedColumn,
        userColumn
    ];

    const defaultSorted = [
        {
            dataField: DataAnonymizationPropMap.country,
            order: 'desc'
        }
    ];

    const renderedAnonymizationSettings = isIncidentAnonymization
        ? anonymizationSettings
              .filter((setting) => setting.countryCode !== 'DEFAULT')
              .map((setting) => {
                  let renderedTime;
                  if (setting.days == null) {
                      renderedTime = NEVER_ANONYMIZE_MESSAGE;
                  } else {
                      renderedTime = setting.days + (setting.days !== 1 ? ' days' : ' day');
                  }
                  return {
                      country: countries.getName(setting.countryCode, 'en'),
                      time: renderedTime,
                      dateLastModified: new Date(setting.dateLastModified).toLocaleString(),
                      user: setting.modifiedBy
                  };
              })
        : phoneAnonymizationSettings
              .filter((setting) => setting.countryCode !== 'DEFAULT')
              .map((setting) => {
                  let Types;
                  if (!setting.incidentTypeNames.length) {
                      Types = NEVER_ANONYMIZE_MESSAGE;
                  } else {
                      Types = [...setting.incidentNewTypeNames].sort().join(', ');
                  }
                  return {
                      country: countries.getName(setting.countryCode, 'en'),
                      eventTypes: Types,
                      dateLastModified: new Date(setting.dateLastModified).toLocaleString(),
                      user: setting.modifiedBy
                  };
              });

    return (
        <div id='Overrides'>
            {isIncidentAnonymization && <label className='dataAnonTableLabel'>Event History Overrides</label>}
            {isPhoneAnonymization && <label className='dataAnonTableLabel'>Phone Number Overrides</label>}
            <Button
                title='Filter and Sort'
                onClick={() => setTableFilters((prevTableFilters: boolean) => !prevTableFilters)}
                size='sm'
                variant='outline'
                style={{ float: 'right' }}
            >
                <FaFilter />
            </Button>
            <Button
                title='Add override'
                size='sm'
                type='Button'
                onClick={() => {
                    setOpenAddOverride(true);
                }}
                style={styles.addOverrideButton}
            >
                Add Override
            </Button>
            {isIncidentAnonymization && openAddOverride && (
                <Override overrideType={OverrideTypes.AddOverride} isVisible={setOpenAddOverride} />
            )}
            {isPhoneAnonymization && openAddOverride && (
                <PhoneNumberOverride
                    overrideType={OverrideTypes.AddOverride}
                    isVisible={setOpenAddOverride}
                />
            )}
            <br />
            <ToolkitProvider
                keyField={DataAnonymizationPropMap.country}
                data={renderedAnonymizationSettings}
                columns={
                    isIncidentAnonymization ? anonymizationSettingsColumns : PhoneAnonymizationSettingsColumns
                }
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            filter={filterFactory()}
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive'
                            keyField={DataAnonymizationPropMap.country}
                            data={renderedAnonymizationSettings}
                            columns={
                                isIncidentAnonymization
                                    ? anonymizationSettingsColumns
                                    : PhoneAnonymizationSettingsColumns
                            }
                            noDataIndication={NoDataIndication(isLoadingAnonymizationSettings)}
                            defaultSorted={defaultSorted}
                        />
                    );
                }}
            </ToolkitProvider>
            {isIncidentAnonymization && openEditOverride && (
                <Override
                    selectedCountry={selectedCountry}
                    selectedDays={selectedDays}
                    overrideType={OverrideTypes.EditOverride}
                    isVisible={setOpenEditOverride}
                />
            )}
            {isPhoneAnonymization && openEditOverride && (
                <PhoneNumberOverride
                    selectedCountry={selectedCountry}
                    selectedEventTypes={selectedEventTypes}
                    overrideType={OverrideTypes.EditOverride}
                    isVisible={setOpenEditOverride}
                />
            )}
        </div>
    );
};

export default DataAnonymizationTable;
