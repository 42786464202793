import React, { useEffect } from 'react';
import '../../css/PermissionsTeamsTable.css';
import BootstrapTable from 'react-bootstrap-table-next';
import Button from 'react-bootstrap/Button';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaFilter } from 'react-icons/fa';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import {
    PermissionsTeamsPropMap,
    PermissionsTeamsTitlePropMap
} from '../../lib/dictionaries/PermissionsTeamsDictionary';
import { NoDataIndication } from './NoDataIndication';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import { CONFIRM_DELETE_PERMISSIONS_TEAMS_MESSAGE } from '../../lib/constants';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PermissionsTeamsEntries } from '../../lib/types';
import {
    removePermissionsTeamAsync,
    getPermissionsTeamsAsync
} from '../../slices/admin/rolePermissionsSlice';
import { useAppDispatch } from '../../context/store';
import PermissionsTeamAdd from '../Modals/PermissionsTeamAdd';
import { showConfirmationDialog } from '../Commons';

type Props = { tabName: 'admin' | 'business' | 'eventOwner' | 'user' };

const styles = {
    buttonStyle: {
        backgroundColor: '#EBECFF',
        width: '90px',
        height: '30px',
        border: 'none',
        color: '#0036C8',
        fontSize: '9pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'right' as const,
        marginRight: '8px',
        marginTop: '2px'
    },
    rowDeleteStyle: {
        color: '#0D4AFF',
        padding: 0,
        alignSelf: 'flex-start',
        textAlign: 'left' as const,
        fontSize: '14px'
    }
};

// eslint-disable-next-line
const PermissionsTeamsTable = ({ tabName }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [openAddTeams, setOpenAddTeams] = useToggle(false);
    const [tableFilters, setTableFilters] = useToggle(false);

    const { [`${tabName}PermissionsTeams`]: permissionsTeams, isGettingPermissionsTeams } = useSelector(
        (state: RootState) => state.rolePermissions
    );
    useEffect(() => {
        if (!permissionsTeams) {
            dispatch(getPermissionsTeamsAsync(tabName));
        }
    }, []);
    countries.registerLocale(localeEn);

    const handleDelete = async (roleType: string, extId: string): Promise<void> => {
        const confirmed = await showConfirmationDialog({
            title: CONFIRM_DELETE_PERMISSIONS_TEAMS_MESSAGE,
            icon: 'warning',
            iconColor: '#F29718'
        });
        if (confirmed && extId != null) {
            dispatch(removePermissionsTeamAsync(roleType, extId));
        }
    };

    // eslint-disable-next-line
    const rowDelete = (value: any, row: any) => {
        return (
            <Button
                title='Delete'
                style={styles.rowDeleteStyle}
                variant='link'
                onClick={() => {
                    handleDelete(tabName, row.extId);
                }}
            >
                Delete
            </Button>
        );
    };

    // eslint-disable-next-line
    const nameFormatter = (value: any, row: any) => {
        return (
            <a
                href={`https://permissions.amazon.com/a/team/${row.extId}`}
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: '#0D4AFF' }}
            >
                {value}
            </a>
        );
    };

    const permissionsTeamsColumns = [
        {
            dataField: PermissionsTeamsPropMap.name,
            text: PermissionsTeamsTitlePropMap.name,
            sort: true,
            sortFunc(a: string, b: string, order: string) {
                return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
            },
            filter: tableFilters
                ? textFilter({
                      placeholder: PermissionsTeamsTitlePropMap.name + '...'
                  })
                : false,
            style: {
                cursor: 'pointer'
            },
            formatter: nameFormatter
        },
        {
            dataField: PermissionsTeamsPropMap.extId,
            text: PermissionsTeamsTitlePropMap.abacusId,
            sort: true,
            sortFunc(a: string, b: string, order: string) {
                return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
            },
            filter: tableFilters
                ? textFilter({
                      placeholder: PermissionsTeamsTitlePropMap.abacusId + '...'
                  })
                : false
        },
        {
            dataField: PermissionsTeamsPropMap.lastUpdated,
            text: PermissionsTeamsTitlePropMap.dateLastModified,
            headerClasses: tableFilters ? 'non-filterable-column' : undefined
        },
        {
            dataField: PermissionsTeamsPropMap.updatedBy,
            text: PermissionsTeamsTitlePropMap.user,
            headerClasses: tableFilters ? 'non-filterable-column' : undefined
        },
        {
            dataField: PermissionsTeamsPropMap.remove,
            text: PermissionsTeamsTitlePropMap.remove,
            headerClasses: tableFilters ? 'non-filterable-column' : undefined,
            formatter: rowDelete
        }
    ];

    const defaultSorted = [
        {
            dataField: PermissionsTeamsPropMap.name,
            order: 'desc'
        }
    ];

    const renderedPermissionsTeams = permissionsTeams
        .filter((team: PermissionsTeamsEntries) => team.roleType.toLowerCase() === tabName.toLowerCase())
        .map((team: PermissionsTeamsEntries) => {
            return {
                [PermissionsTeamsPropMap.name]: team.name,
                [PermissionsTeamsPropMap.extId]: team.extId,
                [PermissionsTeamsPropMap.lastUpdated]: new Date(team.lastUpdated).toLocaleString(),
                [PermissionsTeamsPropMap.updatedBy]: team.updatedBy
            };
        });

    return (
        <div id='permissionsTeams' style={{ width: '90%' }}>
            <label style={{ fontSize: '1.7em', fontStyle: 'bold' }}>Permissions Teams</label>
            <Button
                title='Filter and Sort'
                onClick={() => setTableFilters((prevTableFilters: boolean) => !prevTableFilters)}
                size='sm'
                variant='outline'
                style={{ float: 'right' }}
            >
                <FaFilter />
            </Button>
            <Button
                title='Add Team'
                size='sm'
                type='Button'
                onClick={() => {
                    setOpenAddTeams(true);
                }}
                className='btn btn-secondary btn-sm'
                style={styles.buttonStyle}
            >
                Add Team
            </Button>
            {openAddTeams && <PermissionsTeamAdd role={tabName} isVisible={setOpenAddTeams} />}
            <br />
            <ToolkitProvider
                keyField={PermissionsTeamsPropMap.name}
                data={renderedPermissionsTeams}
                columns={permissionsTeamsColumns}
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            filter={filterFactory()}
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive'
                            keyField={PermissionsTeamsPropMap.extId}
                            data={renderedPermissionsTeams}
                            columns={permissionsTeamsColumns}
                            noDataIndication={NoDataIndication(isGettingPermissionsTeams)}
                            defaultSorted={defaultSorted}
                            pagination={paginationFactory({})}
                        />
                    );
                }}
            </ToolkitProvider>
        </div>
    );
};
export default PermissionsTeamsTable;
