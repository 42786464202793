import React, { Fragment, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { Row, Col, Button, Popover, OverlayTrigger, Form } from 'react-bootstrap';
import {
    getRoleBySitesAsync,
    getUserSites,
    getRoleByLoginsSucceeded,
    resetRoleByLogins
} from '../../slices/userProfile/userProfileSlice';
import { useAppDispatch } from '../../context/store';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { PopoverStyle } from '../../lib/types';

interface SiteOption {
    value: string;
    label: string;
}

const SiteLookup = (): JSX.Element => {
    const MAXIMUM_NUMBER_OF_SELECTED_SITES = 5;
    const ERROR_POPOVER_STYLE: PopoverStyle = {
        backgroundColor: '#ffcccc',
        color: 'white'
    };

    const dispatch = useAppDispatch();
    const [selectedSites, setSelectedSites] = useState<SiteOption[]>([]);
    const [isDisplayingSitePopover, setIsDisplayingSitePopover] = useToggle(false);
    const { userSites, isGettingUserSites, isGettingLatestAppVersion } = useSelector(
        (state: RootState) => state.userProfile
    );
    const { isGettingRoleByLogins } = useSelector((state: RootState) => state.userProfile);
    const { resolvedUsers, failedUsers } = useSelector((state: RootState) => state.userProfile.roleByLogins);

    useEffect(() => {
        dispatch(getUserSites());
        dispatch(resetRoleByLogins());
    }, []);

    const indicatorSeparatorStyle = {
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none'
        })
    };

    const selectFacilitiesOptionStyle = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#EBECFF' : state.isFocused ? '#EBECFF' : null,
            color: 'black',
            ':hover': {
                backgroundColor: '#EBECFF',
                color: 'black'
            }
        })
    };

    const scrollSelectStyle = {
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: '#F9FAFB',
            border: '1px solid',
            borderColor: '#D0D5DD',
            borderRadius: '4px'
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: 'black'
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: '#667085',
            ':hover': {
                backgroundColor: 'transparent',
                color: 'black'
            }
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            overflow: 'auto',
            maxHeight: '72px'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#667085'
        }),
        ...indicatorSeparatorStyle,
        ...selectFacilitiesOptionStyle
    };

    const handleSiteChange = (newSelectedSites: SiteOption[], actionType: { action: string }): void => {
        switch (actionType.action) {
            case 'deselect-option':
                setSelectedSites(newSelectedSites);
                setIsDisplayingSitePopover(false);
                dispatch(
                    getRoleByLoginsSucceeded({
                        resolvedUsers: resolvedUsers.filter((user) =>
                            newSelectedSites.some((site: SiteOption) => site.value.includes(user.location))
                        ),
                        failedUsers
                    })
                );
                break;
            case 'select-option':
                if (newSelectedSites.length > MAXIMUM_NUMBER_OF_SELECTED_SITES) {
                    setIsDisplayingSitePopover(true);
                } else {
                    setSelectedSites(newSelectedSites);
                    setIsDisplayingSitePopover(false);
                }
                break;
            case 'remove-value':
                setSelectedSites(newSelectedSites ? newSelectedSites : []);
                setIsDisplayingSitePopover(false);
                dispatch(
                    getRoleByLoginsSucceeded({
                        resolvedUsers: newSelectedSites
                            ? resolvedUsers.filter((user) =>
                                  newSelectedSites.some((site: SiteOption) =>
                                      site.value.includes(user.location)
                                  )
                              )
                            : [],
                        failedUsers
                    })
                );
                break;
            case 'clear':
                setSelectedSites([]);
                setIsDisplayingSitePopover(false);
                dispatch(resetRoleByLogins());
                break;
            default:
        }
    };

    const displaySitePopover = (): JSX.Element => {
        let message = '';
        if (selectedSites.length >= MAXIMUM_NUMBER_OF_SELECTED_SITES)
            message = `You cannot add more than 5 sites at a time.`;
        else {
            return <div />;
        }
        return renderPopover(ERROR_POPOVER_STYLE, message);
    };

    const renderPopover = (style: PopoverStyle, content: string): JSX.Element => {
        return (
            <Popover style={style} id={''}>
                <Popover.Content>{content}</Popover.Content>
            </Popover>
        );
    };

    const isSelectedSitesValid = (): boolean => {
        return selectedSites.length > 0 && selectedSites.length <= MAXIMUM_NUMBER_OF_SELECTED_SITES;
    };

    const generateReport = (): void => {
        if (isSelectedSitesValid()) {
            const siteNames = selectedSites.map((site: SiteOption) => site.value);
            dispatch(getRoleBySitesAsync(siteNames));
        }
    };

    const Option = (props: any): JSX.Element => {
        return (
            <div>
                <components.Option {...props}>
                    <div className='custom-checkbox-container'>
                        <input
                            className='mr-2 custom-checkbox'
                            type='checkbox'
                            checked={props.isSelected}
                            onChange={() => null}
                        />
                        <label>{props.label}</label>
                    </div>
                </components.Option>
            </div>
        );
    };

    return (
        <Fragment>
            <Row className='pl-4 pt-2'>
                <Col>
                    <Form.Label style={{ fontSize: '1rem' }}>Facilities</Form.Label>
                </Col>
            </Row>
            <Row className='pl-4 pb-3'>
                <Col md={'3'}>
                    <OverlayTrigger
                        placement='auto'
                        show={isDisplayingSitePopover}
                        overlay={displaySitePopover()}
                        rootClose
                        onToggle={setIsDisplayingSitePopover}
                    >
                        <Select
                            options={userSites}
                            styles={scrollSelectStyle}
                            onChange={handleSiteChange}
                            placeholder={
                                isGettingUserSites
                                    ? `Loading Facilities...`
                                    : `Select up to ${MAXIMUM_NUMBER_OF_SELECTED_SITES} Facilities...`
                            }
                            value={selectedSites}
                            isDisabled={isGettingRoleByLogins}
                            isLoading={isGettingUserSites || isGettingLatestAppVersion}
                            components={{ Option }}
                            closeMenuOnSelect={MAXIMUM_NUMBER_OF_SELECTED_SITES === selectedSites.length}
                            hideSelectedOptions={false}
                            isMulti
                        />
                    </OverlayTrigger>
                </Col>
                <Col md={'2'} style={{ marginLeft: '-20px' }}>
                    <Button
                        className='GenerateReportButton'
                        style={{
                            height: '100%',
                            width: '8.5rem',
                            cursor: `${isGettingRoleByLogins ? 'wait' : 'pointer'}`,
                            fontSize: '13px',
                            backgroundColor: '#0D4AFF'
                        }}
                        onClick={() => generateReport()}
                        disabled={
                            isGettingLatestAppVersion ||
                            isGettingRoleByLogins ||
                            isGettingUserSites ||
                            !isSelectedSitesValid()
                        }
                        block
                    >
                        Generate Report
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SiteLookup;
